import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import { useUserStore } from '~/stores/user'

import type Lang from '~/types/lang'

/**
 * @deprecated Use composables/usePatchUserLang.ts.
 */
export default defineComponent({
  computed: {
    ...mapState(useUserStore, ['IS_LOGGED_IN']),
  },
  methods: {
    ...mapActions(useUserStore, {
      SET_USER: 'SET',
    }),
    /**
     * @param lang
     * @deprecated Use composables/usePatchUserLang.ts.
     */
    updateStoreSideLang(lang: Lang) {
      if (this.IS_LOGGED_IN) this.SET_USER({ lang })

      this.$i18n.setLocale(lang)
      // TODO: check this cookie
      const selectedLangCookie = useCookie('selectedLang')
      selectedLangCookie.value = lang
      // this.$cookies.set('selectedLang', lang)
    },
    /**
     * @param lang
     * @deprecated Use composables/usePatchUserLang.ts.
     */
    updateLang(lang: Lang): void {
      this.updateStoreSideLang(lang)
      // TODO: check this locale switch
      const switchLocalePath = useSwitchLocalePath()
      this.$router.push(switchLocalePath(lang))
    },
    /**
     * @param lang
     * @param email
     * @param programmaticUpdate
     * @deprecated Use composables/usePatchUserLang.ts.
     */
    patchLang(lang: Lang, email: string, programmaticUpdate = true) {
      $coreFetch
        .$patch(`/auth/user/${email}/lang/`, {
          lang,
        })
        .catch((err) => {
          this.$sentry?.captureException(err)
        })
        .finally(() => {
          if (programmaticUpdate) this.updateLang(lang)
        })
    },
  },
})
